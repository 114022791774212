<template>
  <div class="top_details_wrap" v-if="show">
    <NavBar title="Thông điệp" left-arrow @click-left="handleBack" />
    <div class="content_bar">
      <div class="userInfo">
        <div class="time">
          <span>Người gửi:&nbsp;</span>{{ $format(data.indate) }}
        </div>
        <div class="nickname">
          <span>Gửi thời gian:&nbsp;</span>{{ data.username }}
        </div>
      </div>
      <h3 class="content_title">Nội dung:</h3>
      <div class="top_details_content" v-html="data.content"></div>
      <div class="height20"></div>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  components: { NavBar },
  props: {
    data: {
      type: Object,
      default: () => ({}),
      require: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "show",
    event: "updateShow",
  },
  methods: {
    handleBack() {
      this.$emit("updateShow", false);
    },
  },
};
</script>

<style lang="less" scoped>
.content_bar {
  height: calc(100vh - 46px);
  overflow-y: auto;
}
.userInfo {
  padding: 20px;
  .nickname {
    font-size: 28px;
    color: #999;
    margin-top: 8px;
    > span {
      color: #333;
    }
  }
  .time {
    font-size: 28px;
    color: #999;
    > span {
      color: #333;
    }
  }
}
.content_title {
  padding: 0 20px;
  padding-top: 20px;
  margin-bottom: 0;
}
.top_details_content {
  padding: 20px;
  overflow: auto;
  // height: calc(100vh - 46px - 20px - 120px);
}
.height20 {
  height: 80px;
}
</style>